import React from 'react'
import {Typography} from '@material-ui/core'

export const Footer = () => {
  return (
    <>
      <Typography variant="body1" style={{textAlign: 'center',marginTop: '2rem'}}>
        Made with ❤️ by Bharat
      </Typography>
    </>
  )
}
